<header id="page-topbar" >
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a href="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.svg" alt="Inkoru" height="42">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-dark.png" alt="Inkoru" height="30">
                    </span>
                </a>

                <a href="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo-light.svg" alt="Inkoru" height="42">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-light.png" alt="Inkoru" height="30">
                    </span>
                </a>
            </div>

            <button type="button" class="d-block d-lg-none btn btn-sm px-0 font-size-16 d-lg-none header-item waves-effect waves-light" data-toggle="collapse" data-target="#topnav-menu-content">
                <i class="fa fa-fw fa-bars"></i>
            </button>

        </div>

        <div class="d-flex">
            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-updates" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-new-box"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" aria-labelledby="page-header-updates">
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0" key="t-notifications">Novedades</h6>
                            </div>
                            <div class="col-auto d-none">
                                <a href="/registrocambios" class="small" key="t-view-all"> Ver todas</a>
                            </div>
                        </div>
                    </div>
                    <div data-simplebar style="max-height: 230px;">
                        <div class="text-reset notification-item" *ngIf="estudio.tarifa_id != 8 && estudio.pais_id == 67">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxl-whatsapp"></i> Recordatorios por Whatsapp</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se pueden enviar automáticamente los recordatorios de cita a través de Whatsapp. (Este servicio tiene un coste adicional de 5€ al mes) <a href="https://api.whatsapp.com/send?phone=+34644944535&text=Hola%21%20Me%20gustar%C3%ADa%20activar%20el%20servicio%20de%20env%C3%ADo%20autom%C3%A1tico%20de%20recordatorios%20de%20cita%20a%20trav%C3%A9s%20de%20Whatsapp%20para%20mi%20estudio%2C%20%C2%BFme%20pod%C3%A9is%20ayudar%3F" target="_blank"><u>Solicita ahora la activación</u></a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-nail"></i> Agujas y catéters</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se pueden <a [routerLink]="['/estudios/agujas/',user?.estudio_id]">registrar las agujas y catéters</a> utilizadas en cada trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-calendar-plus"></i> Cita de cliente</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se puede crear una nueva cita desde el perfil del cliente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-badge-account-alert"></i> Aviso de nueva inspección</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Aviso cuando un estudio cercano registre una nueva inspección.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-hat-fedora"></i> Registro de inspecciones</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Nueva funcionalidad de <a [routerLink]="['/estudios/inspecciones/',user?.estudio_id]">registro de inspecciones</a>, para generar una red colaborativa entre estudios.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-stats"></i> Estadísticas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Mejoras: Se añade rango de fechas en <a [routerLink]="['/estudios/stats/']">página de estadísticas</a> y datos sobre el impacto ecológico.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="fas fa-list-alt"></i> Inventario en cada trabajo</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes gestionar el inventario dentro de cada trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-id-card"></i> Registro de clientes con DNI</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Los clientes ya pueden añadir las imágenes de su DNI desde el formulario de registro a través del QR.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-rotate-right"></i> Gastos recurrentes</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes establecer si un <a [routerLink]="['/estudios/gastos/',user?.estudio_id]">gasto es recurrente</a> e Inkoru lo añadirá automáticamente cuando corresponda.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-calendar-blank-multiple"></i> Calendario en modo Año</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se ha añadido una nueva vista "Año" <a [routerLink]="['/trabajos/calendario/']">al calendario</a> para ver todos los trabajos de una forma más global.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="fas fa-list-alt"></i> Control de Inventario</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se ha creado una nueva <a [routerLink]="['/estudios/inventarios/',user?.estudio_id]">sección de Inventario</a> desde la que puedes llevar el control de stock de todo el material del estudio y saber en todo momento qué hay que reponer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-purchase-tag"></i> Etiquetas de productos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes añadir etiquetas a los productos para identificarlos y filtrarlos mejor. Además se han implementado mejoras <a [routerLink]="['/estudios/productos/',user?.estudio_id]">en esta sección</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-id-card"></i> ¿DNI obligatorio?</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes establecer que todos los clientes añadan la imagen de su DNI sin tener que indicarlo en cada trabajo. Accede al apartado "Consentimientos informados" dentro del menú "Estudio" > "Información"</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxl-whatsapp"></i> Mensaje de recordatorio de cita personalizado</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes personalizar el mensaje que se genera cuando mandamos un recordatorio por Whatsapp de forma manual a cada cliente. <a href="https://youtu.be/CL3r6YjAXqo" target="_blank">Mira cómo funciona</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-sticker-plus-outline"></i> No añadir señal en factura</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes excluir la señal pagada en la factura de un trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-account-alert"></i> Mejor control de clientes conflictivos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ahora es más fácil identificar a los clientes conflictivos, ya sea marcándolos manualmente o de forma automática cuando no asisten a sus citas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-account-group"></i> Citas de grupo</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes crear citas para grupos de una forma mucho más ágil. Selecciona a varios clientes desde <a [routerLink]="['/trabajos/citamultiple/']">una misma pantalla</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-zoom-in"></i> Tickets de venta y facturas automáticas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes crear tickets de venta y facturas automáticas de cada servicio. Ahora todo está agrupado en <a [routerLink]="['/estudios/ventas/',user?.estudio_id]">el panel de ventas</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-zoom-in"></i> Buscador global de trabajos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Localiza <a [routerLink]="['/trabajos/buscador']">cualquier trabajo</a> filtrando por nombre o apellidos del cliente, su dni, teléfono, email o por palabras de la descripción.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-images"></i> Subida múltiple de imágenes</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes subir múltiples imágenes a la vez desde la ficha de cada trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-calendar-blank-multiple"></i> Aviso de citas solapadas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Desde modo 'normal' o 'express', si una nueva cita coincide con otra en día, hora y cabina, se muestra un aviso antes de guardar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bxs-droplet-half"></i> Añadir tintas utilizadas desde el trabajo</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes incluir los pigmentos utilizados que se mostrarán en el consentimiento informado, desde la ficha del trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-account-multiple"></i> Fusión de clientes duplicados</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes <a [routerLink]="['/clientes/duplicados']">fusionar los clientes duplicados</a> de tu base de datos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-sticker-plus-outline"></i> Tickets desde el perfil del cliente</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes crear Tickets de Venta directamente desde el perfil de cada cliente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-line-chart"></i> Gastos incluidos en la liquidación</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se incluyen los gastos guardados en la <a [routerLink]="['/liquidacion']">liquidación del estudio</a> mostrando el balance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="bx bx-world"></i> Perfil público del estudio</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes <a [routerLink]="['/estudios/edit/',user?.estudio_id]">generar el perfil público del estudio</a> para que nuevos clientes puedan solicitar citas con los artistas, gestionándolo todo desde tu panel.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-credit-card-outline"></i> Control de gastos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes <a [routerLink]="['/estudios/gastos/',user?.estudio_id]">añadir y contabilizar los gastos</a> que se van generando cada mes en el estudio y exportarlos en formato Excel.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-calendar-week"></i> Creación de eventos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes crear eventos de varios días y asignarlos a un artista (para convenciones, viajes... etc) <a href="https://youtu.be/zFHP0UoE3PM" target="_blank">Mira cómo funciona</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-calendar-alert"></i> Días festivos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se pueden añadir días festivos desde la <a [routerLink]="['/estudios/edit/',user?.estudio_id]">información del estudio</a>, y estos se mostrarán en rojo en el calendario.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped"><i class="mdi mdi-calendar-edit"></i> Ajustes calendario</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se puede definir la intensidad del color con que se muestran los trabajos de cada artista en la vista mes del calendario.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Clientes flexibles</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se puede añadir la propiedad 'Cliente flexible' a cada cliente, para poder acceder rápidamente a los clientes que tienen flexibilidad en cuento a la asignación de fechas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Felicitación de Cumpleaños</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">El sistema envía automáticamente un <a [routerLink]="['/comunicaciones']">email personalizado</a> a los clientes que cumplan años.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Gestión de cabinas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Gestión de cabinas y asignación de trabajos a cada cabina. Se añade vista de trabajos por cabina en el calendario.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Ausencias de artistas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Los días de ausencia de cada artista se mostrarán con un aviso en el calendario.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Mail marketing</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer"><a [routerLink]="['/comunicaciones/emails']">Envío de comunicaciones</a> a todos los clientes con filtro de estado o tipo de trabajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Cliente no presentado</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Al finalizar una cita, se puede indicar que el cliente no se ha presentado.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Artistas sin email</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se pueden <a [routerLink]="['/artistas/new']">crear artistas</a> sin email. No tendrán acceso a su cuenta, ni reciben notificaciones.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Control de Stock</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Gestión del Stock de los artículos en venta. Aviso vía email si el umbral de stock es bajo.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Liquidación artista/estudio</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se muestran los <a [routerLink]="['/artistas/liquidacion']">totales</a> en la zona inferior sin necesidad de generar el PDF.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Recuento diario</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se incluyen las <a [routerLink]="['/recuento']">señales cobradas</a> del mismo día. Mejoras visuales.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Mejora en calendario</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">En modo 'mes', se muestra el tipo de trabajo. Mejoras visuales.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Señales en trabajo Express</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se puede introducir la información sobre señales en la modalidad de <a [routerLink]="['/trabajos/express']">trabajo 'Express'</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Rendimiento de Artistas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Sección con el <a [routerLink]="['/artistas/totales']">rendimiento de cada artista</a> basado en sus trabajos realizados.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Añadir imágenes a las citas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya se pueden añadir imágenes dentro de cada trabajo. Referencias, proceso, trabajo final...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Consentimientos informados personalizados</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se puede personalizar el <a [routerLink]="['/comunicaciones']">contenido de los consentimientos</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Título evento Google Calendar</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ahora los títulos de las citas en Google Calendar incluyen el nombre del cliente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Política de cancelación</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Definir la <a [routerLink]="['/estudios/edit/',user?.estudio_id]" >política de cancelaciones</a> del estudio. Se mostrará en los emails que reciben los clientes con la info de su cita.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Tickets y ventas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se pueden crear <a [routerLink]="['/estudios/productos/',user?.estudio_id]" >productos</a> y generar <a [routerLink]="['/estudios/tickets/',user?.estudio_id]">tickets de venta</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Creación de trabajos express</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se desarrolla una <a href="/trabajos/express">forma más rápida de crear citas</a> pudiendo dar de alta al cliente y añadir todos los datos de la cita en una única página.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Idioma consentimentos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ahora se puede <a href="/trabajos/express">seleccionar el idioma</a> en que se generará cada consentimiento informado. Disponible en español, catalán, inglés y francés.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Recopilación de consentimientos</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se crea una página donde se recopilan todos los <a href="/trabajos/archivoconsentimientos"><u>consentimientos informados generados</u></a>, para poder acceder a ellos de una manera más rápida.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">'Nuevo trabajo' más ágil</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se simplifica el proceso de creación de <a href="/trabajos/new"><u>nuevos trabajos</u></a>, pudiendo ocultar todos los campos 'no obligatorios'.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Servicios del estudio</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Ya puedes definir los <a [routerLink]="[ '/estudios/edit', estudio.id ]"><u>servicios que ofrece tu estudio</u></a>, para evitar mostrarte información que no necesitas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Calendario mejorado</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se muestran las horas en el modo 'Semana' y se añade el formato 'Agenda'. <a href="/trabajos/calendario"><u>Ir al calendario</u></a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Consentimientos informados</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se generan consentimientos informados más completos y adaptados a la normativa.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Permisos para artistas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Se puede definir si <a href="/artistas/list"><u>los artistas</u></a> pueden crear sus propias citas y ver la agenda de sus compañeros.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item">
                            <div class="media">
                                <div class="mr-3">
                                    <span>
                                        <i class="mdi mdi-new-box display-5 text-dark"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1 font-weight-bold text-primary" key="t-shipped">Gestor de tintas</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Nueva sección en la que <a [routerLink]="[ '/estudios/tintas', estudio.id ]"><u>añadir las tintas homologadas</u></a> disponibles en el estudio. Podrán seleccionarse posteriormente para mostrarse en el consentimiento informado.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" *ngIf="rol != 1"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-bell" [ngClass]="{'bx-tada': num_notificaciones > 0}"></i>
                    <span class="badge badge-danger badge-pill p-1" *ngIf="num_notificaciones > 0"><i class="bx bxs-error-circle font-size-14"></i></span>
                    <span class="badge badge-danger badge-pill" *ngIf="!num_notificaciones"></span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    aria-labelledby="page-header-notifications-dropdown">
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0" key="t-notifications"> Notificaciones </h6>
                            </div>
                            <div class="col text-right">
                                <h6 class="m-0" key="t-notifications"><a href="/notificaciones" class="font-weight-medium">Ver todas <i class="bx bx-share ml-1"></i></a></h6>
                            </div>
                        </div>
                    </div>
                    <div data-simplebar style="max-height: 230px;">
                        <div class="p-3 text-center" *ngIf="num_notificaciones <= 0">
                            <div class="mb-3"><img class="avatar-sm" src="assets/images/iconos/ico_notificaciones_3d.png"></div>
                            <span class="d-block text-center text-info"><strong>¡No hay notificaciones!</strong></span>
                        </div>
                        <div class="p-3 border-top" *ngIf="num_notificaciones > 0">
                            <span class="d-block text-dark">Tienes {{ num_notificaciones }} notificaciones sin leer.</span>
                        </div>
                        <div [routerLink]="[ '/trabajos/view', nota.trabajo_id ]" class="text-reset notification-item" *ngFor="let nota of notas">
                            <div class="media" *ngIf="nota.user">
                                <img src="{{ global_url + nota.user.avatar }}"
                                    class="mr-3 rounded-circle avatar-xs bg-soft-dark" alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Nuevo comentario</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-simplified">{{ nota.user.name }} {{ nota.user.apellido1 }} ha escrito un <b>nuevo comentario</b> en la cita #{{ nota.trabajo_id }}.</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago"> Hace {{ tiempoTranscurridoNota(nota) }}</span></p>
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" (click)="confirmarNotificacionNota(nota)" class="btn btn-outline-info btn-sm waves-effect waves-light"><i class="far fa-comment-dots font-size-16"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-reset notification-item" *ngFor="let cita of citas_finalizadas">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-info rounded-circle">
                                        <i class="mdi mdi-checkbox-marked-circle-outline font-size-22"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 *ngIf="cita.tipo_proyecto == 2" class="mt-0 mb-1" key="t-shipped">Walk-in finalizado</h6>
                                    <h6 *ngIf="cita.fase_cita == 1" class="mt-0 mb-1" key="t-shipped">Cita Pre-proyecto finalizada</h6>
                                    <h6 *ngIf="cita.fase_cita == 2" class="mt-0 mb-1" key="t-shipped">Cita de boceto finalizada</h6>
                                    <h6 *ngIf="cita.fase_cita == 3" class="mt-0 mb-1" key="t-shipped">Cita de tatuaje finalizada</h6>
                                    <h6 *ngIf="cita.tipo_proyecto == 3" class="mt-0 mb-1" key="t-shipped">Cita de piercing finalizada</h6>
                                    <h6 *ngIf="cita.tipo_proyecto == 4" class="mt-0 mb-1" key="t-shipped">Cita de láser finalizada</h6>
                                    <h6 *ngIf="cita.tipo_proyecto == 5" class="mt-0 mb-1" key="t-shipped">Cita de micropigmentación finalizada</h6>
                                    <h6 *ngIf="cita.tipo_proyecto == 6" class="mt-0 mb-1" key="t-shipped">Cita de manicura finalizada</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">La <a [routerLink]="[ '/trabajos/view', cita.id ]">cita #{{ cita.id }}</a> entre {{ cita.artista_nombre }} {{ cita.artista_apellido }} y {{ cita.cliente_nombre }} {{ cita.cliente_apellido1 }} ha terminado. ¿Ha ido todo bien?</p>
                                        
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" (click)="confirmarNotificacionCita(cita.id)" class="d-none btn btn-outline-success btn-sm waves-effect waves-light"><i class="mdi mdi-thumb-up"></i></button>
                                        <button type="button" class="ml-2 btn btn-outline-info btn-sm waves-effect waves-light" (click)="citaSelected(cita)" data-toggle="modal" data-target=".modal-finalizar-cita"><i class="bx bxs-edit"></i> Finalizar cita</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <a class="text-reset notification-item" *ngFor="let notificacion of notificaciones">
                            <div class="media">
                                <div class="avatar-xs mr-3" *ngIf="notificacion.tipo == 'recuento'">
                                    <span class="avatar-title bg-success rounded-circle">
                                        <i class="bx bx-euro font-size-24"></i>
                                    </span>
                                </div>
                                <div class="avatar-xs mr-3" *ngIf="notificacion.tipo == 'abono'">
                                    <span class="avatar-title bg-info rounded-circle">
                                        <i class="fas fa-coins font-size-18"></i>
                                    </span>
                                </div>
                                
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1" key="t-shipped">{{ notificacion.titulo }}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">{{ notificacion.descripcion }}</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago"> Hace {{ tiempoTranscurridoNotificacion(notificacion) }}</span></p>
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" (click)="confirmarNotificacion(notificacion.id)" class="btn btn-outline-success btn-sm waves-effect waves-light"><i class="mdi mdi-thumb-up"></i></button>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <span *ngFor="let artista of artistas">
                            <a [routerLink]="[ '/artistas/edit', artista.id ]" class="text-reset notification-item" *ngIf="!artista.codigo_higienico && rol == 4">
                                <div class="media">
                                    <div class="avatar-xs mr-3">
                                        <span class="avatar-title bg-warning rounded-circle font-size-16">
                                            <i class="bx bx-error font-size-19"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos de {{ artista.nombre }} {{ artista.apellido1 }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">Se debe acreditar que el artista posee el <b>diploma Higiénico Sanitario</b> escribiendo el código del mismo en sus datos profesionales.</p>
                                        </div>
                                        <div class="mt-3">
                                            <button type="button" class="btn btn-outline-info btn-sm w-xs waves-effect waves-light">Solucionar</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a [routerLink]="[ '/artistas/view', artista.id ]" class="text-reset notification-item" *ngIf="isHepatitis(artista) && rol == 4">
                                <div class="media">
                                    <div class="avatar-xs mr-3">
                                        <span class="avatar-title bg-warning rounded-circle font-size-16">
                                            <i class="fas fa-syringe font-size-19"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos de {{ artista.nombre }} {{ artista.apellido1 }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">Verifica su <b>vacunación contra la Hepatitis</b> subiendo un documento que lo acredite.</p>
                                        </div>
                                        <div class="mt-3">
                                            <button type="button" class="btn btn-outline-info btn-sm w-xs waves-effect waves-light">Solucionar</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a [routerLink]="[ '/artistas/view', artista.id ]" class="text-reset notification-item" *ngIf="isTetanos(artista) && rol == 4">
                                <div class="media">
                                    <div class="avatar-xs mr-3">
                                        <span class="avatar-title bg-warning rounded-circle font-size-16">
                                            <i class="fas fa-syringe font-size-19"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos de {{ artista.nombre }} {{ artista.apellido1 }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">Verifica su <b>vacunación contra el Tétanos</b> subiendo un documento que lo acredite.</p>
                                        </div>
                                        <div class="mt-3">
                                            <button type="button" class="btn btn-outline-info btn-sm w-xs waves-effect waves-light">Solucionar</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a [routerLink]="[ '/artistas/edit', artista.id ]" class="text-reset notification-item"  *ngIf="!artista.firma && rol == 4">
                                <div class="media">
                                    <div class="avatar-xs mr-3">
                                        <span class="avatar-title bg-warning rounded-circle font-size-16">
                                            <i class="fas fa-signature font-size-18"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos de {{ artista.nombre }} {{ artista.apellido1 }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">Se necesita la <b>firma del artista</b> para poder generar los consentimientos informados.</p>
                                        </div>
                                        <div class="mt-3">
                                            <button type="button" class="btn btn-outline-info btn-sm w-xs waves-effect waves-light">Solucionar</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a [routerLink]="[ '/artistas/edit', artista.id ]" class="text-reset notification-item"  *ngIf="!artista.dias?.length > 0 && rol == 4">
                                <div class="media">
                                    <div class="avatar-xs mr-3">
                                        <span class="avatar-title bg-warning rounded-circle font-size-16">
                                            <i class="far fa-calendar-alt font-size-19"></i>
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos de {{ artista.nombre }} {{ artista.apellido1 }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">No se ha establecido el <b>horario de trabajo</b> del artista.</p>
                                        </div>
                                        <div class="mt-3">
                                            <button type="button" class="btn btn-outline-info btn-sm w-xs waves-effect waves-light">Solucionar</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img class="rounded-circle header-profile-user bg-white" *ngIf="user!.avatar" src="{{ global_url + user!.avatar }}"
                        alt="{{ user!.name }}">
                    <span class="d-none d-xl-inline-block ml-1" key="t-henry">{{ user!.name }} {{ user!.apellido1 }}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right menu-usuario">
                    <!-- item-->
                    <a class="dropdown-item d-block" (click)="configuracion()" *ngIf="rol != 1"><i class="bx bx-user font-size-16 align-middle mr-1"></i>  <span key="t-settings">Mis datos</span></a>
                    <a *ngIf="rol != 2" class="dropdown-item d-block" [routerLink]="['/configuracion/']"><i class="bx bx-cog font-size-16 align-middle mr-1"></i>  <span key="t-settings">Configuración</span></a>
                    <a *ngIf="rol == 1" class="dropdown-item d-block" [routerLink]="['/tarifas/list']"><i class="bx bx-money font-size-16 align-middle mr-1"></i>  <span key="t-settings">Tarifas</span></a>
                    <a class="dropdown-item" (click)="pause()"><i class="bx bx-lock-open font-size-16 align-middle mr-1"></i> <span key="t-lock-screen">Bloquear</span></a>
                    <a *ngIf="rol <= 3" class="dropdown-item" [routerLink]="['/ayuda/']"><i class="bx bx-info-circle font-size-16 align-middle mr-1"></i>  <span key="t-settings">Panel de ayuda</span></a>
                    <a *ngIf="rol <= 3" class="dropdown-item d-block" href="https://inkoru.com/soporte.html" target="_blank"><i class="mdi mdi-help-circle-outline font-size-16 align-middle mr-1"></i>  <span key="t-settings">Soporte</span></a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" (click)="logout()"><i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> <span key="t-logout">Cerrar sesión</span></a>
                </div>
            </div>

            <!-- CONTADOR DE DÍAS ANTES DEL PAGO -->
            <div *ngIf="rol > 1 && rol <= 3 && estudio.subscribed == 0" class="dropdown d-lg-inline-block ml-1 bg-dark">
                <button type="button" class="btn header-item noti-icon waves-effect"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="text-warning py-1 px-2 font-weight-bold border border-warning">{{ dias_restantes }} días</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div class="px-lg-2">
                        <div class="row no-gutters">
                            <div class="col p-3">
                                <span class="text-dark"><i class="bx bxs-time bx-tada"></i> Faltan <b>{{ dias_restantes }} días</b> para que termine el periodo de prueba, después podrás acceder a Inkoru <a [routerLink]="['/pago/']">mediante suscripción</a>.</span><br>
                                <span *ngIf="user!.promo" class="text-dark mt-2 d-block">Si has introducido un <b>código promocional</b> para ampliar los días de prueba, avísanos para que lo activemos.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</header>

<div *ngIf="mostrarAlerta" class="alert alert-warning alert-dismissible fade show aviso-inspeccion" role="alert">
    <i class="mdi mdi-alert-outline me-2"></i>
    {{ mensajeAlerta }}
    <a class="btn btn-dark btn-sm ml-2 mr-2" [routerLink]="['/estudios/inspecciones/',user?.estudio_id]">Ver mapa de inspecciones</a>
    <button type="button" class="btn btn-primary btn-sm" (click)="marcarAvisosComoLeidos()">Ok! marcar como leído</button>
</div>

<div class="topnav" *ngIf="rol">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

            <div class="collapse navbar-collapse" id="topnav-menu-content">
                <ul class="navbar-nav">

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-dashboard" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="user?.rol_id == 2 || user?.rol_id == 3">
                            <i class="bx bx-store mr-2"></i><span key="t-dashboards">Estudio</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                    
                            <!-- Información -->
                            <a [routerLink]="['/estudios/edit/',user?.estudio_id]" class="dropdown-item" key="t-informacion"
                               *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_infoestudio == 1)" 
                               [ngClass]="{'active': route.includes('/estudios/edit')}">Información</a>
                    
                            <!-- Gestión Submenú -->
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-gestion" role="button" 
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span key="t-gestion">Gestión</span> <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="topnav-gestion">
                                    <a [routerLink]="['/estudios/stats/']" class="dropdown-item" key="t-estadisticas" 
                                       *ngIf="rol <= 2 || (user?.rol_id == 3 && roleinfo?.permiso_totales == 1)"
                                       [ngClass]="{'active': route == '/estudios/stats'}">Estadísticas</a>
                                    <a [routerLink]="['/liquidacion/']" class="dropdown-item" key="t-liquidacion" 
                                       *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_liquidacion == 1)"
                                       [ngClass]="{'active': route == '/liquidacion'}">Liquidación estudio</a>
                                    <a [routerLink]="['/estudios/cabinas/',user?.estudio_id]" class="dropdown-item" key="t-cabinas" 
                                       *ngIf="(user?.rol_id == 2 && estudio?.puntos_trabajo == 0) || (user?.rol_id == 3 && roleinfo?.permiso_cabinas == 1 && estudio?.puntos_trabajo == 0)"
                                       [ngClass]="{'active': route.includes('/estudios/cabinas')}">Gestión de cabinas</a>
                                    <a [routerLink]="['/estudios/gastos/',user?.estudio_id]" class="dropdown-item" key="t-gastos" 
                                       *ngIf="(user?.rol_id <= 2) || (user?.rol_id == 3 && roleinfo?.permiso_gastos == 1)"
                                       [ngClass]="{'active': route.includes('/estudios/gastos')}">Gestión de gastos</a>
                                    <a [routerLink]="['/estudios/inspecciones/',user?.estudio_id]" class="dropdown-item" key="t-inspecciones"
                                       *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                       [ngClass]="{'active': route.includes('/estudios/inspecciones')}">Inspecciones <span class="badge bg-primary text-light">Beta</span></a>
                                </div>
                            </div>
                    
                            <!-- Inventario Submenú -->
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-inventario" role="button" 
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span key="t-inventario">Inventario</span> <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="topnav-inventario">
                                    <a [routerLink]="['/estudios/tintas/',user?.estudio_id]" class="dropdown-item" key="t-tintas" 
                                       *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                       [ngClass]="{'active': route.includes('/estudios/tintas')}">Gestor de tintas</a>
                                    <a [routerLink]="['/estudios/agujas/',user?.estudio_id]" class="dropdown-item" key="t-agujas" 
                                       *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                       [ngClass]="{'active': route.includes('/estudios/agujas')}">Gestor de agujas <span class="badge bg-success text-light">Nuevo</span></a>
                                    <a [routerLink]="['/estudios/inventarios/',user?.estudio_id]" class="dropdown-item" key="t-inventario" 
                                       *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                       [ngClass]="{'active': route.includes('/estudios/inventarios')}">Inventario global</a>
                                </div>
                            </div>
                    
                            <!-- Ventas -->
                            <div class="dropdown" *ngIf="user?.estudio.ventas == 1">
                                <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-ventas" role="button" 
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span key="t-ventas">Ventas</span> <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="topnav-ventas">
                                    <div class="dropdown">
                                        <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-productos" role="button" 
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span key="t-productos">Productos</span> <div class="arrow-down"></div>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="topnav-productos">
                                            <a [routerLink]="['/estudios/productos/',user?.estudio_id]" class="dropdown-item" key="t-productos" 
                                               *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                               [ngClass]="{'active': route.includes('/estudios/productos')}">Todos los productos</a>
                                            <a [routerLink]="['/estudios/productos/',user?.estudio_id,'new']" class="dropdown-item" key="t-nuevo-producto" 
                                               *ngIf="user?.rol_id == 2 || user?.rol_id == 3">Nuevo producto</a>
                                            <a [routerLink]="['/estudios/catproductos/',user?.estudio_id]" class="dropdown-item" key="t-cat-productos" 
                                               *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                               [ngClass]="{'active': route.includes('/estudios/catproductos')}">Categorías de producto</a>
                                        </div>
                                    </div>
                                    <a [routerLink]="['/estudios/ventas/',user?.estudio_id]" class="dropdown-item" key="t-ventas"
                                       *ngIf="user?.rol_id == 2 || user?.rol_id == 3"
                                       [ngClass]="{'active': route.includes('/estudios/ventas')}">Ventas</a>
                                </div>
                            </div>
                    
                        </div>
                    </li>
                    

                    <li class="nav-item dropdown" *ngIf="rol == 4">
                        <a class="nav-link" id="topnav-components" role="button" (click)="perfil()">
                            <i class="dripicons-user mr-2"></i><span key="t-components">Mi perfil</span>
                        </a>
                    </li>
                    
                    
                    <li class="nav-item dropdown" *ngIf="rol < 4">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-uielement" role="button" [ngClass]="{'active': route == '/managers/list' || route == '/managers/new' || route.includes('/managers/edit') || route.includes('/managers/view')}"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="dripicons-user-id mr-2"></i>
                            <span key="t-ui-elements"> Managers</span> 
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a (click)="perfil()" *ngIf="rol == 3" class="dropdown-item mi-perfil-mng" key="t-informacion">Mi perfil</a>
                            <a [routerLink]="['/managers/list/']" class="dropdown-item" [ngClass]="{'active': route == '/managers/list'}" key="t-informacion" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_otrosmanagers == 1)">Todos los managers</a>
                            <a [routerLink]="['/managers/new/']" class="dropdown-item" [ngClass]="{'active': route == '/managers/new'}" *ngIf="rol <= 2" key="t-informacion">Nuevo manager</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown" *ngIf="rol != 4">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-pages" role="button" [ngClass]="{'active': route == '/artistas/list' || route == '/artistas/new' || route.includes('/artistas/edit') || route.includes('/artistas/view') || route == '/artistas/totales'}"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_perfilartistas == 1 || user?.rol_id == 3 && roleinfo?.permiso_artistas == 1 || user?.rol_id == 3 && roleinfo?.permiso_liquidacion == 1 || user?.rol_id == 3 && roleinfo?.permiso_rendimiento == 1)">
                            <i class="dripicons-user mr-2"></i><span key="t-apps">Artistas</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/artistas/list/']" class="dropdown-item" [ngClass]="{'active': route == '/artistas/list'}" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_perfilartistas == 1)" key="t-informacion">Todos los artistas</a>
                            <a [routerLink]="['/artistas/new/']" class="dropdown-item" [ngClass]="{'active': route == '/artistas/new'}" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_artistas == 1)" key="t-informacion">Nuevo artista</a>
                            <a [routerLink]="['/artistas/liquidacion/']" class="dropdown-item" [ngClass]="{'active': route == '/artistas/liquidacion'}" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_liquidacion == 1)" key="t-informacion">Liquidación artistas</a>
                            <a [routerLink]="['/artistas/totales/']" class="dropdown-item" [ngClass]="{'active': route == '/artistas/totales'}" *ngIf="(user?.rol_id == 2 && artistas?.length >= 2) || (user?.rol_id == 3 && roleinfo?.permiso_rendimiento == 1 && artistas?.length >= 2)" key="t-informacion">Rendimiento</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown" *ngIf="rol <= 3 || (rol == 4 && roleinfo?.ver_datos_clientes == 1)">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-components" role="button" [ngClass]="{'active': route == '/clientes/list' || route == '/clientes/new' || route.includes('/clientes/edit') || route.includes('/clientes/view')}"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="dripicons-user-group mr-2"></i><span key="t-components">Clientes</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/clientes/list/']" [ngClass]="{'active': route == '/clientes/list'}" class="dropdown-item" key="t-informacion">Todos los clientes</a>
                            <a [routerLink]="['/clientes/new/']" [ngClass]="{'active': route == '/clientes/new'}" class="dropdown-item" *ngIf="rol <= 3" key="t-informacion">Nuevo cliente</a>
                        </div>
                    </li>
        

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-agenda" role="button" [ngClass]="{'active': route == '/trabajos/calendario' || route == '/trabajos/new' || route.includes('/trabajos/edit') || route.includes('/trabajos/list') || route == '/recuento' || route == '/trabajos/archivoconsentimientos' || route.includes('/trabajos/express') }"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-calendar mr-2"></i><span key="t-extra-pages">Agenda</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-agenda">
                            <a [routerLink]="['/trabajos/new/']" class="dropdown-item" key="t-nuevo-trabajo" [ngClass]="{'active': route == '/trabajos/new'}" *ngIf="(rol <= 3 && rol > 1) || roleinfo?.crear_cita == 1">Nuevo trabajo</a>
                            <a [routerLink]="['/trabajos/express/']" class="dropdown-item" key="t-trabajoexpress" [ngClass]="{'active': route == '/trabajos/express'}" *ngIf="(rol <= 3 && rol > 1) || roleinfo?.crear_cita == 1">Trabajo express</a>
                            <a [routerLink]="['/trabajos/calendario/']" class="dropdown-item" key="t-calendario" *ngIf="rol != 1" [ngClass]="{'active': route == '/trabajos/calendario'}">Calendario</a>
                            <a [routerLink]="['/trabajos/list/']" class="dropdown-item" key="t-todostrabajos" *ngIf="rol != 1" [ngClass]="{'active': route == '/trabajos/list'}">Todos los trabajos</a>
                            <a [routerLink]="['/trabajos/citamultiple/']" class="dropdown-item" key="t-buscadortrabajos" *ngIf="rol != 1" [ngClass]="{'active': route == '/trabajos/citamultiple'}">Citas de grupo <span class="badge bg-primary text-light">Nuevo</span></a>
                            <a [routerLink]="['/trabajos/buscador/']" class="dropdown-item" key="t-buscadortrabajos" *ngIf="user?.rol_id == 2 || user?.rol_id == 3" [ngClass]="{'active': route == '/trabajos/buscador'}">Buscador de trabajos</a>
                            <a [routerLink]="['/recuento']" class="dropdown-item" key="t-recuento" [ngClass]="{'active': route == '/recuento'}" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_recuento == 1)">Recuento diario</a>
                            <a [routerLink]="['/trabajos/archivoconsentimientos']" class="dropdown-item" key="t-archivoconsentimientos" [ngClass]="{'active': route == '/trabajos/archivoconsentimientos'}" *ngIf="rol <= 3 && rol > 1">Consentimientos firmados</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown" *ngIf="rol <= 3">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-layout" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bxs-user-voice mr-2"></i><span key="t-layouts">Comunicación</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/comunicaciones']" class="dropdown-item" key="t-email">Comunicaciones</a>
                            <a [routerLink]="['/comunicaciones/emails']" class="dropdown-item" key="t-email" *ngIf="user?.rol_id == 2 || (user?.rol_id == 3 && roleinfo?.permiso_comunicaciones == 1)">Emails</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown" *ngIf="rol == 4 && roleinfo?.ver_totales == 1">
                        <a class="nav-link" id="topnav-components" role="button" [routerLink]="['/artistas/liquidacion/']">
                            <i class="dripicons-graph-line mr-2"></i><span key="t-components">Liquidación</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>


 <!--  Modal para finalizar la cita antes de los previsto -->
 <div class="modal fade modal-finalizar-cita" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content border border-dark">
            <div class="modal-header bg-dark mb-3">
                <h5 class="modal-title mt-0 text-white" id="myLargeModalLabel">Finalizar esta cita</h5>
                <button type="button" class="close  text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="check_cita_normal" [checked]="citaNormal" (change)="citaNormal = !citaNormal">
                                <label class="custom-control-label" for="check_cita_normal">La cita ha transcurrido con normalidad.</label>
                            </div>
                        </div>
                        <div class="col-md-12" id="campo_clienteausente" *ngIf="!citaNormal">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="check_cita_clienteausente" [(ngModel)]="clienteAusente_notif" [checked]="clienteAusente_notif" (change)="clienteAusente_notif = !clienteAusente_notif">
                                <label class="custom-control-label" for="check_cita_clienteausente">El cliente no se ha presentado.</label>
                            </div>
                            <span *ngIf="clienteAusente_notif" class="small text-danger mt-1 d-block">Se marcará automáticamente a este cliente como "conflictivo" por Ghosting.</span>
                        </div>
                        <div class="col-md-12 mt-3" id="campo_problema" *ngIf="!citaNormal">
                            <div class="form-group">
                                <textarea id="formrow-observaciones-tatuaje" class="form-control" [(ngModel)]="cita_nota" [ngModelOptions]="{standalone: true}" rows="3" placeholder="Escribe aquí los comentarios..."></textarea>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="!clienteAusente_notif">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="check_duracion" [checked]="duracionNormal" (change)="duracionNormal = !duracionNormal">
                                <label class="custom-control-label" for="check_duracion">Ha finalizado a la hora prevista (<strong>{{ parseHora(cita_selected?.horaf) }}</strong>h).</label>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3" id="campo_duracion" *ngIf="!duracionNormal">
                            <div class="form-group">
                                <input type="time" [(ngModel)]="cita_hora" id="formrow-duracion-trabajo" class="form-control" name="duracion-trabajo" aria-describedby="duracion-trabajo">
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="!clienteAusente_notif">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="check_precio" [checked]="precioNormal" (change)="precioNormal = !precioNormal">
                                <label class="custom-control-label" for="check_precio">No hay cambios en el precio total (<strong>{{ cita_selected?.precio }}</strong>€).</label>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3" id="campo_precio" *ngIf="!precioNormal">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="number" [(ngModel)]="cita_precio" id="formrow-duracion-trabajo" class="form-control" name="duracion-trabajo" aria-describedby="duracion-trabajo" placeholder="500" value="500">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="duracion-trabajo">€</span>
                                    </div>
                                </div>
                                <p class="text_aprox_modal" *ngIf="cita_selected?.aproximado">Este precio era aproximado, actualiza o confirma para poder finalizar la cita. <br> <span *ngIf="cita_selected?.aproximado_comentario">Motivo: {{ cita_selected?.aproximado_comentario}}</span></p>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="!clienteAusente_notif">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="check_comisionnotiffull" [checked]="comisionnotifNormal" (change)="comisionnotifNormal = !comisionnotifNormal">
                                <label class="custom-control-label" for="check_comisionnotiffull">Se mantiene la comisión del artista (<strong>{{ cita_selected?.comision_artista }}</strong>%).</label>
                            </div>
                        </div>
                        <div class="col-md-12" id="campo_comisionnotif" *ngIf="!comisionnotifNormal ">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="number" [(ngModel)]="cita_comisionnotif" id="formrow-comisionnotif" class="form-control" name="comisionnotif" aria-describedby="comisionnotif" placeholder="500" value="500">
                                    <div class="input-group-append">
                                        <span class="input-group-text" id="comisionnotif">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10 mb-3" *ngIf="!clienteAusente_notif">
                            <div class="form-group">
                                <label class="control-label" for="formrow-formapago-input">
                                    Forma de pago:
                                </label>
                                <select class="form-control select2" id="formrow-formapago-input" [(ngModel)]="cita_formapago" [ngModelOptions]="{standalone: true}">
                                    <option> </option>
                                    <option value="efectivo">Efectivo</option>
                                    <option value="tarjeta">Tarjeta</option>
                                    <option value="transferencia">Transferencia</option>
                                    <option value="bizum">Bizum</option>
                                    <option value="paypal">Paypal</option>
                                    <option value="yappy">Yappy</option>
                                </select>
                            </div>
                        </div>                      
                    </div>
                    <div class="mt-3">
                        <button type="button" (click)="finalizarCita()" class="btn btn-primary waves-effect waves-light mr-2">Finalizar cita</button>
                        <button type="button" class="btn btn-dark" data-dismiss="modal">Cerrar</button>
                    </div>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare let gtag: Function;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  formLogin!: UntypedFormGroup;
  pasError = false; 
  levelPass = 0;
  sendPin= false;
  formForgot!: UntypedFormGroup;
  formNewPassword!: UntypedFormGroup;
  newPassword = false;
  loading = false;
  submitted = false;
  idUser: any;
  returnUrl!: string;
  userUpdate: any;
  wizard: any;
  constructor(private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private api: ApicallsService,
              private router: Router,
              private authenticationService: AuthenticationService) {

              if (this.authenticationService.currentUserValue) {
                  this.router.navigate(['/dashboard']);
              }
  }
  loginform = true;
  recoverform = false;
  
  ngOnInit() {
    this.formLogin = this.formBuilder.group({
        username: ['', [ Validators.required, Validators.email]],
        password: ['', Validators.required]
    });
 
    this.formForgot = this.formBuilder.group({
      username: ['', [ Validators.required, Validators.email]],
    });
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

 
  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  login(formValue: any) {
    this.authenticationService.login( formValue.username , formValue.password )
      .pipe(first())
      .subscribe( data => {
        console.log('Estado de suscripción:', data.user?.estudio?.subscribed);
        // Acción inicio_sesion
        const esSuscrito = data.user && data.user.estudio && data.user.estudio.subscribed === 1;
        // Envía el evento a Google Analytics 4
        gtag('event', 'inicio_sesion', {
          'estado_usuario': 'logueado',
          'usuario_suscrito': esSuscrito
        });
         this.router.navigate(['/dashboard']);
        },
        error => {
          this.toastr.error('No tienes permiso para entrar a la aplicación');
        }
      );
  }

  sendForgot(formValue: any) {
    this.api.forgotPassword(formValue).subscribe( (res: any) => {
      if ( res.error) {
        this.toastr.error('Ocurrió un error y no se ha podido enviar el correo.');
      } else {
        this.toastr.success('Revisa tu correo para poder cambiar tu contraseña');
          this.formForgot.controls.username.reset();
      }
    }, error => {
      this.toastr.error('Ocurrió un error y no se ha podido enviar el correo.');
    });

  }


}
